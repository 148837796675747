 
<template>
  
    
    <b-sidebar
      id="orders-filters-sidebar"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <div>
        <div class="text-center">
            <b-card-text class="mt-1 mb-2 h4 color-inherit text-reset">
                {{ $t('Filters') }}
            </b-card-text>
        </div>

        <div class="m-1 mt-3">
            <div class="mt-1">
                <h6 class="filter-title">
                {{ $t('status') }}
                </h6>
                
                <b-button
                variant="outline-primary"
                size="sm"
                class="filter-button"
                v-for="(btn, idx) in orderStatuses" 
                :key="idx"
                :pressed="ordersFilters.statuses ? ordersFilters.statuses.indexOf(btn.value) != -1 : false"
                @click="handleClickedState(btn.value)"
                >
                    {{ $t(btn.text) }}
                </b-button>               

            </div>
        </div>

        <div class="m-1 mt-3">
            <div class="mt-1">
                <h6 class="filter-title">
                {{ $t('date') }}
                </h6>
                
                <b-input-group class="mb-1">
                  <flat-pickr class="form-control filter-date" :config="configFromDateTimePicker" v-model="ordersFilters.fromDate" placeholder="Από" @on-change="onFromChange" />
                  <b-input-group-append is-text @click="fromDate=null">
                    <feather-icon icon="XIcon"/>
                  </b-input-group-append>
                </b-input-group>

                <b-input-group class="mb-1">
                  <flat-pickr class="form-control filter-date" :config="configToDateTimePicker" v-model="ordersFilters.toDate" placeholder="Έως" @on-change="onToChange" />
                  <b-input-group-append is-text @click="toDate=null">
                    <feather-icon icon="XIcon"/>
                  </b-input-group-append>
                </b-input-group>

            </div>
        </div>
        <!--
        <div class="m-1 mt-3">
          <b-button variant="outline-danger" @click="clearFilters()">{{ $t('Καθαρισμός φίλτρων') }}</b-button>
        </div>
        -->
        
    </div>
    </b-sidebar>
  
</template>

<script>
import {BButton, BSidebar, BAvatar, BListGroup, BListGroupItem, BCardText, BInputGroup, BInputGroupAppend} from 'bootstrap-vue'
import { orderStatuses } from '@/../appConfig.js'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BButton,
    BSidebar,
    BAvatar, 
    BListGroup, 
    BListGroupItem, 
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    flatPickr
  },
  data() {
    return {
      orderStatuses: orderStatuses,
      configFromDateTimePicker: {
        minDate: null,
        maxDate: null,
        altInput: true,
        altFormat: "d-m-Y",
      },
      configToDateTimePicker: {
        minDate: null,
        maxDate: null,
        altInput: true,
        altFormat: "d-m-Y",
      },
    }
  },
  props: ['ordersFilters'],
  methods: {
    onFromChange(selectedDates, dateStr, instance) {
        this.$set(this.configToDateTimePicker, 'minDate', dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
        this.$set(this.configFromDateTimePicker, 'maxDate', dateStr);
    },
    clearFilters() {
      this.ordersFilters.statuses = []
      this.ordersFilters.fromDate = null
      this.ordersFilters.toDate = null
    },
    emitFiltersEvent() {
      this.$emit('update:ordersFilters', this.ordersFilters)
    },
    handleClickedState(status){
      let pos = this.ordersFilters.statuses.indexOf(status);
      if(pos == -1){
        this.ordersFilters.statuses.push(status);
      }
      else{
        this.ordersFilters.statuses.splice(pos, 1);
      }
    },
  },
  watch: {
    ordersFilters: {
     handler(){
       this.emitFiltersEvent()
     },
     deep: true
    }
  },
}
</script>
 
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>