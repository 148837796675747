import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default function useOrdersList() {

  // Use toast
  const toast = useToast()

  const refOrderListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'number', label: 'ΚΩΔΙΚΟΣ' ,sortable: true },
    { key: 'date',  label: 'ΗΜΕΡΟΜΗΝΙΑ', sortable: true },
    { key: 'state', label: 'ΚΑΤΑΣΤΑΣΗ', sortable: true },
    { key: 'buyer.company.name',  label: 'ΑΓΟΡΑΣΤΗΣ', sortable: true },
    { key: 'actions' , label: 'ΕΝΕΡΓΕΙΕΣ'},
  ]
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('date')
  const isSortDirDesc = ref(true)
  const firstLoad = ref(true)
  const ordersFilters = ref({statuses: [], fromDate: null, toDate: null})
  const refreshButton = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refOrderListTable.value ? refOrderListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: (perPage.value * currentPage.value) < localItemsCount ? perPage.value * currentPage.value : localItemsCount,
      of: localItemsCount,
    }
  })

  watch(refreshButton, () => {
    fetchOrders.value = fetchOrdersInitial
  },
  { deep: true })

  watch(ordersFilters, () => {
    refetchData()
  },
  { deep: true })

  watch(firstLoad, () => {
    fetchOrders.value = fetchOrdersSubsequent
  })

  const refetchData = () => {
    refOrderListTable.value.refresh()
  }

  const fetchOrdersSubsequent = (ctx) => {
    return store.getters['orders/filteredOrders'](ordersFilters.value)
  }

  const fetchOrdersInitial = (ctx, callback) => {
    store.dispatch('orders/getOrders')
      .then(() => {
        firstLoad.value = false
        callback(store.getters['orders/filteredOrders']({}))
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error fetching orders' list"),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchOrders = ref(fetchOrdersInitial)


  return {
    fetchOrders,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOrderListTable,
    ordersFilters,
    refreshButton,
    refetchData,
  }
}
