const orderStatuses =  [
    { value: 'new', text: 'Pending' },
    { value: 'confirmed', text: 'In progress' },
    { value: 'complete', text: 'Complete' },
    { value: 'cancelled', text: 'Cancelled' }
  ]

  const creditTermStatuses =  [
    { value: 'pending', text: 'Pending' },
    { value: 'approved', text: 'Approved' },    
    { value: 'rejected', text: 'Rejected' }
  ]

  const languages = [{
    "locale": "el_GR",
    "label": "greek",
    "img": "@/assets/images/flags/gr.png"
  }, 
  // {
  //   "locale": "en",
  //   "label": "english",
  //   "img": "@/assets/images/flags/en.png"
  // }
]
  
  const defaultLanguage = "el_GR"
  
  const languageSettings = {
    "defaultLanguage": defaultLanguage,
    "languages": (() => { // use this function to have default language always first
      let langs =  languages.filter((language) => {return language.locale == defaultLanguage})
      langs.splice(1, 0, ...languages.filter((language) => {return language.locale != defaultLanguage}))
      return langs;
    })()
  }

  export { 
    orderStatuses,
    creditTermStatuses,
    languageSettings,
  }