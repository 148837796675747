<template >
   

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('Εγγραφές') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />

        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              type = "search"
              class="d-inline-block mr-1"
              :placeholder="$t('Αναζήτηση...')"
            />
            
          </div>
        </b-col>
      </b-row>

    </div>

    <div class="mx-2">
      <b-button
        variant="outline-primary"
        size="sm"
        class="filter-button mb-2"
        v-for="(btn, idx) in ordersFilters.statuses" 
        :key="idx"
        @click="removeFilter('status', btn)"
        >
          <feather-icon icon="XIcon"/>
          {{ $t(orderStatusTexts[btn]) }}
        </b-button>

        <b-button
        variant="outline-primary"
        size="sm"
        class="filter-button mb-2"
        v-if="ordersFilters.fromDate || ordersFilters.toDate" 
        @click="removeFilter('date')"
        >
          <feather-icon icon="XIcon"/>
            <template v-if="ordersFilters.fromDate">
               {{ $t(ordersFilters.fromDate) | date_format('DD/MM/YYYY') }}
            </template>
            <template v-else>
              ... 
            </template>
            {{ $t('to') }}
            <template v-if="ordersFilters.toDate">
               {{ $t(ordersFilters.toDate) | date_format('DD/MM/YYYY') }}
            </template>
            <template v-else>
              ... 
            </template>
        </b-button>

    </div>

    <b-table
      :key = "keykey"
      ref="refOrderListTable"
      :items="fetchOrders"
      responsive
      :fields="tableColumns"
      :filter="searchQuery"
      primary-key="number"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('NotFound' )+ ' ' + $t('Orders')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      :no-provider-sorting="true"
      :no-provider-paging="true"
      :no-provider-filtering="true"
      :per-page="perPage"
      :current-page="currentPage"
    >

      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>{{ $t('Loading') }}...</strong>
        </div>
      </template>

      <!-- Column: Number -->
      <template #cell(number)="data">
        <b-link
          :to="{ name: 'sales-order', params: { id: data.item.id }}"
          style = "color: #00397A"
        >
          {{ data.value }}
        </b-link>
      </template>

      <!-- Column: Date -->
      <template #cell(date)="data">
        <span class="text-nowrap">
          {{ data.value | date_format('DD/MM/YYYY HH:mm:ss') }}
        </span>
      </template>

      <!-- Column: Status -->
      <template #cell(state)="data">
        <template v-if="data.value == 'new'">
          <b-badge
            pill
            variant="light-warning"
          >
            {{ $t(orderStatusTexts[data.value]) }}
          </b-badge>
        </template>
        <template v-else-if="data.value == 'confirmed'">
          <b-badge
            pill
            variant="light-primary"
          >
            {{ $t(orderStatusTexts[data.value]) }}
          </b-badge>
        </template>
        <template v-else-if="data.value == 'complete'">
          <b-badge
            pill
            variant="light-success"
          >
            {{ $t(orderStatusTexts[data.value]) }}
          </b-badge>
        </template>
        <template v-else-if="data.value == 'cancelled'">
          <b-badge
            pill
            variant="light-danger"
          >
            {{ $t(orderStatusTexts[data.value]) }}
          </b-badge>
        </template>
        <template v-else>
          {{ data.value }}
        </template>
      </template>
      
 

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`order-row-${data.item.id}-send-icon`"
            icon="EditIcon"
            stroke = "#00397A"
            size="16"
            @click="$router.push({ name: 'sales-order', params: { id: data.item.id }})"
            class="cursor-pointer"
          />
          <b-tooltip
            :title="$t('Edit')"
            placement = "left"
            variant="primary"
            :target="`order-row-${data.item.id}-send-icon`"
          />

          
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{$t("Showing")}} {{ dataMeta.from }} {{$t("to")}} {{ dataMeta.to }} {{$t("of")}} {{ dataMeta.of }} {{$t("entries")}}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            :per-page="perPage"
            v-model="currentPage"
            first-number
            last-number
            :total-rows="dataMeta.of"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
    
    <orders-list-filters-sidebar v-bind:ordersFilters.sync="ordersFilters"/>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import useOrdersList from './useOrderList'
import ordersListFiltersSidebar from './OrdersListFiltersSidebar'
import { orderStatuses } from '@/../appConfig.js'
import moduleOrders from '@/store/orders/moduleOrders.js'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BSpinner,
    useOrdersList,
    vSelect,
    ordersListFiltersSidebar,
  },
  setup() {

    // Register module
    if (!store.hasModule('orders')) store.registerModule('orders', moduleOrders)

    const {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      ordersFilters,
      refreshButton,
      refetchData,

    } = useOrdersList()

    return {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      ordersFilters,
      refreshButton,
      refetchData,

    }

  },
  mounted(){
    this.$root.$on('refresh:button', () => {
        this.refreshOrders();
    });
  },
  methods: {
    refreshOrders(){
      this.refreshButton = true
      this.refreshButton = false
      this.searchQuery=''
      this.keykey++
    },
    removeFilter(type, value) {
      switch (type) {
        case 'status':
          let pos = this.ordersFilters.statuses.indexOf(value)
          this.ordersFilters.statuses.splice(pos, 1)
        case 'date':
          this.ordersFilters.fromDate = null
          this.ordersFilters.toDate = null
      }
    }
  },
  data() {
    return {
      keykey:0,
      orderStatusTexts: orderStatuses.reduce((obj, item) => Object.assign(obj, { [item.value]: item.text }), {}),
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.order-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
